.wrapper_rules_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  min-height: 100%;
  z-index: 20;
}

.wrapper_rules_container * {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1;
}

.wrapper_rules_container > .window {
  width: 89%;
  height: 90%;
  min-height: 90%;
  max-height: 90%;
  display: grid;
  grid-template-rows: max-content 1fr;
  background: #2b3242;
  border: 2px solid #4f5c74;
  padding: 2rem;
  position: relative;
}

.wrapper_rules_container > .window > .rules_window_head > .link_box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.wrapper_rules_container > .window > .rules_window_head > .link_box a {
  text-decoration: underline;
  font-size: min(2rem, 4vw);
  color: #ffe32c;
}

.wrapper_rules_container > .window > .iframe_rules {
  margin-top: 1rem;
  width: 100%;
  height: calc(100% - 1rem);
}

.wrapper_rules_container > .window > .iframe_rules iframe,
.wrapper_rules_container > .window > .iframe_rules canvas {
  width: 100%;
  height: 100%;
}

.wrapper_rules_container > .window > .buttonClose {
  cursor: pointer;
  user-select: none;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  background: #394357;
  color: #ffe32c;
  font-size: min(4rem, 5vw);
  transform: translate(50%, -50%) skew(-8deg);
  border: 1px solid #020602;
  padding: 0 min(1rem, 1vw) min(0.3rem, 0.3vw) min(1rem, 1vw);
  border-radius: 5px;
}

@media only screen and (max-width: 700px) {
  .wrapper_rules_container > .window > .buttonClose {
    padding: min(0.5rem, 0.5vw) min(2rem, 2vw) min(1rem, 1vw) min(2rem, 2vw);
  }
}
@media only screen and (max-height: 500px) {
  .wrapper_rules_container > .window > .buttonClose {
    transform: translate(50%, -30%) skew(-8deg);
  }
}
.wrapper_rules_container > .window > .buttonClose:hover {
  background: #4f5c74;
}

.wrapper_rules_container > .window > .buttonClose:active {
  background: #2b3242;
}

.wrapper_rules_container > .window > .rules_window_head > .title {
  color: #ffe32c;
  font-size: min(5rem, 7vw);
  text-align: center;
}

.op_wrapper__disableScroll {
  overflow: hidden;
}