.opWrapper #realityCheckView {
  opacity: 0;
  display: block;
}

.opWrapper #mainRealityCheckPopup, .opWrapper #startRealityCheckPopup {
  width: clamp(30%, 77rem, 90vw);
  position: fixed;
  top: 50%;
  left: 50%;
  user-select: none;
  box-sizing: content-box;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  padding: 2px;
  background: linear-gradient(135deg, #d4528b, #f2943a);
  visibility: hidden;
}

.opWrapper #startRealityCheckPopup {
  width: clamp(30%, 79rem, 90vw);
}

.opWrapper #realityCheckContainer {
  box-sizing: content-box;
  border-radius: 14px;
  font-family: 'UbuntuLight', sans-serif;
  text-transform: none;
  font-weight: bold;
  background: #000000;
  color: #fff;
}

.opWrapper #realityCheckContainer .realityCheck__head {
  padding: 1rem;
  background-color: #232323;
  font-size: clamp(1.5vh, 1.75rem, 3vw);
  font-weight: normal;
  text-align: center;
  border-radius: 13px 13px 0 0;

}

.opWrapper #realityCheckContent {
  padding: 1.5rem 2.5rem 2.8rem 2.5rem;
  color: #fff;
  text-align: center;
}
.opWrapper #startRealityCheckPopup #realityCheckContent {
  padding: 1.5rem 1.5rem 2.8rem 1.5rem;
}
.opWrapper #realityCheckContent .realityCheck__title {
  text-align: center;
  font-family: "Bebas Neue", "UbuntuRegular", sans-serif;
  font-size: 3.2rem;
  color: #ffc230;
  margin-bottom: 2rem;

}
.opWrapper #realityCheckContent .realityCheck__desc {
  font-size: 2.5rem;
  text-align: center;
  line-height: 4.4rem;
  margin-bottom: 1.5rem;
}
.opWrapper #realityCheckContent .realityCheck__desc span {
  white-space: break-spaces;
}
.opWrapper #realityCheckContent .realityCheck__desc .realityCheck__descAmount {
  color: #ffc230;
}

.opWrapper #realityCheckContent .realityCheck__bottomDesc {
  font-size: 1.6rem;
  text-align: center;
  margin-top: 0.8rem;
}

.opWrapper #realityCheckContent .realityCheck__dropdownTitle {
  font-size: 1.6rem;
  text-align: center;
  margin: 0.8rem;
}

.opWrapper #realityCheckContent .realityCheck__dropdownWrapper {
  position: relative;
  height: 4.8rem;
  margin-bottom: 2.8rem;

}

.opWrapper #realityCheckContent #realityCheck__dropdown {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  background: #000000;
  text-align: center;
  border: 2px solid #ffc230;
  border-radius: 8px;
  color: #fff;
  font-size: 2rem;
  transition: max-height 0.3s;
  max-height: 4.8rem;
  overflow: hidden;
}

.opWrapper #realityCheckContent #realityCheck__dropdown.active {
  max-height: 20rem;
}

.opWrapper #realityCheckContent #realityCheck__dropdown .realityCheck__dropdownOption {
  padding: 1.4rem 7.5rem;
  cursor: pointer;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
  .opWrapper #realityCheckContent #realityCheck__dropdown .realityCheck__dropdownOption:hover {
    background: #232323;
  }
}

.opWrapper #realityCheckContent #realityCheck__dropdown::after {
  content: '∨';
  position: absolute;
  right: 5%;
  top: 2.2rem;
  color: #ffc230;
  transform: translateY(-50%);
  font-size: 2rem;
  pointer-events: none;
}
.opWrapper #realityCheckContent #realityCheck__dropdown.active::after {
  content: '∧';
}

.opWrapper #realityCheckContent .realityCheck__button {
  display: inline-block;
  width: 19rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  font-size: 2.5rem;
  border-radius: 8px;
  color: #fff;
}
.opWrapper #realityCheckContent .realityCheck__button_continue {
  background: linear-gradient(45deg, #3fc575, #36bbdc);
}
.opWrapper #realityCheckContent .realityCheck__button_continue:hover {
  background: linear-gradient(45deg, #56d895, #4bd1e8);
}
.opWrapper #realityCheckContent .realityCheck__button_continue:active {
  background: linear-gradient(45deg, #2f9458, #298ca6);
}

.opWrapper #realityCheckContent .realityCheck__button_closeGame {
  background: linear-gradient(45deg, #ed8849, #d65784);
  margin-left: 5rem;
}
.opWrapper #realityCheckContent .realityCheck__button_closeGame:hover {
  background: linear-gradient(45deg, #f3a763, #e474a3);
}
.opWrapper #realityCheckContent .realityCheck__button_closeGame:active {
  background: linear-gradient(45deg, #b26637, #a14263);
}

@media (max-height: 42rem) {
  .opWrapper #mainRealityCheckPopup, .opWrapper #startRealityCheckPopup {
    transform: translate(-50%, -50%) scale(0.8);
  }
}





