html {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  background: #000000;
}

body {
  padding: 0;
  margin: 0;
  color: #000;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  background: #000000;
}

#container, #gameCanvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  overflow: visible;
  width: 100%;
  min-height: 100%;
}

#gameCanvas {
  left: 50%;
  transform: translateX(-50%);
}

input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: -1;
}

/*preloader*/
.progressBg:before {
  background: #0E0637 !important;
}