.opWrapper #freeBets .blocker {
  display: none;
  position: fixed;
  left: 0px;
  right: 0px;
  height: 100%;
  box-sizing: content-box;
  background: rgba(0, 0, 0, 0);
  z-index: 200;
  opacity: 1;
  top: 0;
  pointer-events: all;
}

.opWrapper .freeBetsContent {
  position: fixed;
  top: 50%;
  left: 50%;
  user-select: none;
  box-sizing: content-box;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 1.4rem 6rem 1.4rem 6rem;
  font-family: 'Ubuntu bold', sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
}
.opWrapper .freeBetsContent_smallPadding {
  padding: 1.4rem 3rem 1.4rem 3rem;
}

@media screen and (orientation:portrait) and (hover: none) and (pointer: coarse) {
  .opWrapper .freeBetsContent {
    padding: 2.1rem 4.5rem 2.1rem 4.5rem;
    font-size: 2.7rem;
  }
}

.opWrapper .freeBets__title {
  color: #fff99e;
  white-space: pre;
}
.opWrapper .freeBetsPopup__amount {
  font-size: 2.8em;
  margin: 0.3em;
}
.opWrapper .freeBetsPopup__desc_small {
  color: #fff99e;
  font-size: 0.75em;
  margin-top: 0.55em;
}
.opWrapper .freeBets__button {
  margin-top: 1.375em;
 padding: 0.44em 1.43em 0.33em 1.43em;
  background: rgba(0, 255, 0, 0.55);
  font-size: 1em;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.opWrapper .freeBets__button:hover {
opacity: 0.8;
}
.opWrapper .freeBets__button:active {
  opacity: 0.6;
}

.opWrapper #freeBetsBottomBar {
  display: none;
}

.opWrapper .freeBetsBottomBar__container {
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  width: 100vw;
  font-family: 'Ubuntu bold', sans-serif;
  font-size: 1.45rem;
  color: #fff99e;
}

.opWrapper .freeBetsBottomBar__item {
margin: 0.35em 0.7em;
}
@media screen and (orientation:portrait) and (hover: none) and (pointer: coarse) {
  .opWrapper .freeBetsBottomBar__container {
    font-size: 2.2rem;
  }
}

