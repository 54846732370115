.opWrapper .blocker, .opWrapperMessageBlocker {
  display: none;
  position: fixed;
  left: 0px;
  right: 0px;
  box-sizing: content-box;
  background: rgba(0, 0, 0, 0.78);
  z-index: 200;
  opacity: 1;
  top: 0;
  bottom: 0;
  min-height: 150%;
  pointer-events: all;
}

#messageView {
  z-index: 101;
  font-size: 22px;
}

#messageView .messageContainer {
  width: 25%;
  position: fixed;
  top: 50%;
  left: 50%;
  user-select: none;
  box-sizing: content-box;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgb(44, 44, 50);
  border-radius: 18px;
  padding: 3rem 2rem 1rem 2rem;
  font-family: 'Ubuntu bold', sans-serif;
  text-transform: initial;
}

#messageView #messageTitle {
  font-size: 30px;
  color: rgb(255, 255, 255);
  padding: 0px 0px 15px;
}

#messageView #messageDesc {
  font-size: 20px;
  word-break: break-word;
  color: rgb(180, 180, 189);
  padding: 0px;
  margin-bottom: 1rem;
}

#messageView #buttonOk, #messageView #customButton {
  padding: 1rem;
  background-color: #191919;
  border-radius: 1rem;
  color: white;
  pointer-events: all;
  cursor: pointer;
}

#messageView #customButton {
  display: none;
  text-decoration: none;
}

#messageView #buttonOk:hover, #messageView #customButton:hover {
  background-color: #1e1e1e;
}

#messageView #buttonOk:active, #messageView #customButton:active {
  background-color: #191919;
}

@media only screen and (max-width: 1000px) {
  #messageView #messageTitle {
    font-size: 20px;
  }
  #messageView #messageDesc {
    font-size: 13px;
  }
}
