
.bottomBar {
  display: block;
  background: linear-gradient(90deg, rgba(44,44,51,1) 0%, rgba(59,60,87,1) 100%);
  pointer-events: auto;
  z-index: 18;
}
@media (orientation: landscape) {
  .bottomBar {
    display: block;
    position: fixed;
    top: 0;
    left: auto;
    bottom: auto;
    right: 0;
    width: 50%;
    height: 100%;
    transform: translate(100%, 0);


  }
  .hideBottomBar {
    animation: hide .3s forwards;
  }
  .showBottomBar {
    animation: show .3s forwards;
  }
  @keyframes show {
    from {transform: translate(100%, 0);}
    to {transform: translate(0, 0);}
  }
  @keyframes hide {
    from {transform: translate(0, 0);}
    to {transform: translate(100%, 0);}
  }
}
@media (orientation: portrait) {
  .bottomBar {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    transform: translate(0, 100%);
  }

  .hideBottomBar {
    animation: hide .3s forwards;
  }
  .showBottomBar {
    animation: show .3s forwards;
  }

  @keyframes show {
    from {transform: translate(0, 100%);}
    to {transform: translate(0, 0);}
  }
  @keyframes hide {
    from {transform: translate(0, 0);}
    to {transform: translate(0, 100%);}
  }
}


.bottomBar-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  overflow: hidden;
  padding-top: 2.7rem;
  padding-bottom: 2.7rem;
}

.align-items-center {
  align-items: center;
}

.mt-1 {
  /*margin-top: 1rem;*/
}

.bottomBar-column {
  display: flex;
  flex-direction: column;
  width: 33.3%;
  align-items: center;
  justify-content: center;

}

.bottomBar-headline {
  margin: 0;
  font-family: "Myriad Pro";
  font-size: 1.1em;
  text-align: center;
  color: #facda8;
}

.buttonCircle {
  cursor: pointer;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(assets/images/a9fb419324d4b0df2b7016fa32f3a645.png) no-repeat center;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  border: 1px solid #EABF24;
  -webkit-tap-highlight-color: transparent;
}

.betSelector {
  overflow: hidden;
}

.betSelector-column .buttonCircle {
  margin-top: 1.6em;
}

.betSelector-item {
  text-align: center;
  font-size: 1em;
  color: #fff890;
  white-space: nowrap;
}


.bottomBar .button {
  cursor: pointer;
  pointer-events: all;
  outline: none;
  border: none;
  background-size: cover;
  text-align: center;
  margin: 0 1.35rem;
  -webkit-tap-highlight-color: transparent;

}

.bottomBar .close {
  background: url(assets/images/7ea32f1e63d6234f00c852f668435d24.png) no-repeat center;
  background-size: cover;
  position: absolute;
  top: 5%;
  right: 5%;
  width: 5.4rem;
  height: 5.4rem;

}


.bottomBar .confirm {
  background: url(assets/images/7215aed9e2172846a1d1aaa81881bc77.png) no-repeat center;
  background-size: cover;

  width: 160px;
  height: 53px;
  padding-bottom: 7px;
  margin-top: 3.51rem;

}
.bottomBar .confirm .text {
  position: relative;
  font-family: 'Ubuntu bold', sans-serif;
  font-size: 17px;
  color: transparent;
  text-transform: uppercase;
  background: rgb(177,70,31);
  background: linear-gradient(0deg, rgba(177,70,31,1) 25%, rgba(237,166,90,1) 55%, rgba(238,202,147,1) 72%, rgba(252,246,236,1) 81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  z-index: 3;
  filter: drop-shadow(0px 1px 0.5px #5d272f);

}
.bottomBar .confirm .text::after{
  content: attr(title);
  -webkit-text-fill-color: #f8e9d3;
  color: #f8e9d3;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-3%);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #5d272f;
  z-index: -1;
}

.bottomBar .confirm .text::before{
  content: attr(title);
  -webkit-text-fill-color: #803d24;
  color: #803d24;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(4%);
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #5d272f;
  z-index: -2;
}
.bottomBar .minus {
  background: url(assets/images/2c32a4e641560dcf98200337d61572ed.png) no-repeat center;
  background-size: cover;
  width: 5.4rem;
  height: 5.4rem;

}
.bottomBar .plus {
  background: url(assets/images/a85066b96899e7400704f3f1afb268cc.png) no-repeat center;
  background-size: cover;
  width: 5.4rem;
  height: 5.4rem;

}
.bottomBar .min {
  background: url(assets/images/ff53e1df0fa3eaeb4859b93ba0217289.png) no-repeat center;
  background-size: cover;
  width: 7.29rem;
  height: 5.4rem;

}
.bottomBar .max {
  background: url(assets/images/e58075dfa13f96808361914ea1a31363.png) no-repeat center;
  background-size: cover;
  width: 7.29rem;
  height: 5.4rem;

}

@media (hover: hover) {
  .buttonCircle:hover {
    opacity: 0.8;
  }
  .bottomBar .close:hover {
    background: url(assets/images/2fec19c3624d788738d4dd15949c2fe7.png)  no-repeat center;
    background-size: cover;

  }
  .bottomBar .confirm:hover {
    background: url(assets/images/ad7ae11b6e9f85e0bc7c596dd7832cbb.png)  no-repeat center;
    background-size: cover;

  }
  .bottomBar .minus:hover {
    background: url(assets/images/5152c9c906cf7384a480fec6469b7093.png)  no-repeat center;
    background-size: cover;

  }
  .bottomBar .plus:hover {
    background: url(assets/images/156003ed1c7edf1ce6279c623ace1c63.png)  no-repeat center;
    background-size: cover;

  }
  .bottomBar .min:hover {
    background: url(assets/images/eb4ebd03826ce9edc1654fc6a08fa468.png)  no-repeat center;
    background-size: cover;

  }
  .bottomBar .min:hover {
    background: url(assets/images/eb4ebd03826ce9edc1654fc6a08fa468.png)  no-repeat center;
    background-size: cover;

  }
  .bottomBar .max:hover {
    background: url(assets/images/77cb2cbaa6064fbeddd7154754a36fcd.png)  no-repeat center;
    background-size: cover;

  }
}

.buttonCircle:active {
  opacity: 1;
}
.bottomBar .close:active {
  background: url(assets/images/0b8eeccc26d0af7c1b576b30807cac1a.png) no-repeat center;
  background-size: cover;

}
.bottomBar .confirm:active {
  background: url(assets/images/3c1b3d40421484ab6c151c080e07a1d9.png) no-repeat center;
  background-size: cover;

}
.bottomBar .minus:active {
  background: url(assets/images/5e98df32422b22180d693a870e13b842.png) no-repeat center;
  background-size: cover;

}
.bottomBar .plus:active {
  background: url(assets/images/0202b27716522c67cc9cc7b001c6a0ce.png) no-repeat center;
  background-size: cover;

}
.bottomBar .min:active {
  background: url(assets/images/45c081755618fdc9f4594d8d4ce3668a.png) no-repeat center;
  background-size: cover;

}
.bottomBar .max:active {
  background: url(assets/images/7544ad8c78dbb87f12fb49bd462109cd.png) no-repeat center;
  background-size: cover;

}
