.ui-container__honesty_container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-container__honesty_container__content {
  background-color: var(--color-modal-primary--default, #29263C);
  border-radius: 10px 10px 0 0;
  color: #ffffff;
  text-transform: none;
  width: 1316px;
  height: 635px;
}
.ui-container__honesty_container__title {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 30px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
}
.ui-container__honesty_container__title button:hover {
  opacity: 0.8;
}
.ui-container__honesty_container__body {
  display: flex;
  justify-content: space-between;
}
.ui-container__honesty_container__body > * {
  padding: 30px;
  flex: 1;
}
.ui-container__honesty_container__desc {
  font-size: 24px;
  line-height: 29px;
  opacity: 0.6;
  font-weight: 400;
}
.ui-container__honesty_container__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ui-container__honesty_container__info__title {
  font-size: 24px;
  line-height: 33px;
  opacity: 0.6;
  font-weight: 400;
  margin-bottom: 6px;
}
.ui-container__honesty_container__info input {
  background-color: var(--color-modal-primary--default, #211E34);
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 10px;
  width: 484px;
  height: 62px;
  font-size: 25px;
  line-height: 30px;
  pointer-events: all;
  user-select: auto;
  color: #FFFFFF;
}
.ui-container__honesty_container[data-orientation=portrait] {
  z-index: 1;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__content {
  border-radius: 0;
  background-color: transparent;
  width: 918px;
  height: 1791px;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__content:before {
  content: "";
  position: fixed;
  top: -50%;
  left: -50%;
  height: 200%;
  width: 200%;
  background-color: var(--color-modal-primary--default, #29263C);
  z-index: -1;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__title {
  padding: 100px 30px 43px 30px;
  font-size: 50px;
  line-height: 61px;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__title img {
  max-width: none;
  height: 100%;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__body {
  flex-direction: column;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__desc {
  font-weight: 500;
  font-size: 32px;
  line-height: 160%;
  padding: 40px 30px;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__info {
  padding: 50px 30px;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__info__title {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
}
.ui-container__honesty_container[data-orientation=portrait] .ui-container__honesty_container__info input {
  width: 100%;
  height: 80px;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: >0.3%,last 4 version,not dead
*/
.ui-container,
.system-ui-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: transparent;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  touch-action: none;
  pointer-events: none;
  font-family: "Inter", "Ubuntu bold", "sans-serif";
  text-transform: initial;
}
.ui-container::before,
.system-ui-container::before {
  content: "";
  background-color: transparent;
  touch-action: none;
  pointer-events: none;
  z-index: -1;
  transition: background-color 0.3s;
}
.ui-container *,
.system-ui-container * {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: none;
}
.ui-container #system-interface-container,
.ui-container #player-interface-container,
.system-ui-container #system-interface-container,
.system-ui-container #player-interface-container {
  width: 100%;
  height: 100%;
}
.ui-container button,
.system-ui-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background: none;
  pointer-events: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 0;
  cursor: pointer;
}
.ui-container button:disabled,
.system-ui-container button:disabled {
  cursor: default;
  pointer-events: none;
}

.ui-container.overlay-visible::before,
.system-ui-container.overlay-visible::before {
  content: "";
  position: absolute;
  inset: 0 0 0 0;
  pointer-events: all;
}

.ui-container.overlay-visible::before {
  background-color: var(--color-overlay--default, rgba(4, 0, 29, 0.77));
}

.system-ui-container.overlay-visible::before {
  background-color: var(--color-system-overlay--default, rgba(4, 0, 29, 0.77));
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: >0.3%,last 4 version,not dead
*/
.system-ui-container {
  z-index: 1000;
}
.system-ui-container #system-interface-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.system-ui-container #system-interface-container > * {
  position: absolute;
  inset: 0 0 0 0;
}
@media (hover: hover) {
  .system-ui-container #system-interface-container button.system-ui-container__close_button:hover svg path:first-child {
    fill: var(--color-tournament-primary--hover, #211E34);
    stroke: var(--color-tournament-panel-stroke--default, #F79F2D);
  }
}
.system-ui-container #system-interface-container button.system-ui-container__close_button:active svg path:first-child {
  stroke: var(--color-tournament-panel-stroke--default, #F79F2D);
}
.system-ui-container #system-interface-container button.system-ui-container__close_button:disabled svg path:first-child {
  fill: var(--color-tournament-primary--disabled, #312E42);
  stroke: var(--color-tournament-primary-text--non-active--default, #7F7D8A);
}
.system-ui-container #system-interface-container button.system-ui-container__close_button:disabled svg path:nth-child(n+2) {
  stroke: var(--color-tournament-primary-text--non-active--default, #7F7D8A);
}
.system-ui-container #system-interface-container button.system-ui-container__stroke_button {
  border-radius: 10px;
  border: 2.5px solid #FFFFFF;
  color: #FFFFFF;
}
@media (hover: hover) {
  .system-ui-container #system-interface-container button.system-ui-container__stroke_button:hover {
    border: 2.5px solid var(--color-tournament-panel-stroke--default, #F79F2D);
  }
}
.system-ui-container #system-interface-container button.system-ui-container__stroke_button:active {
  border: 2.5px solid var(--color-tournament-panel-stroke--default, #F79F2D);
}
.system-ui-container #system-interface-container button.system-ui-container__stroke_button:disabled {
  border: 2.5px solid var(--color-tournament-primary-text--non-active--default, #7F7D8A);
  color: var(--color-tournament-primary-text--non-active--default, #7F7D8A);
}
.system-ui-container__modal_wrapper {
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: var(--color-system-overlay--default, rgba(4, 0, 29, 0.77));
  pointer-events: all;
  z-index: 100;
  -webkit-animation: appearance_alpha 0.3s;
  animation: appearance_alpha 0.3s;
}
.system-ui-container__modal_container {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
@-webkit-keyframes appearance_alpha {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appearance_alpha {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
.ui-container {
  z-index: 10;
}
.ui-container button[data-color=primary] {
  background-color: var(--color-primary--default, rgba(3, 7, 80, 0.8));
}
.ui-container button[data-color=primary].selected {
  background-color: var(--color-selected--default, #7BDB00);
}
.ui-container button[data-color=primary]:hover.selected {
  background-color: var(--color-selected--default, #7BDB00);
}
@media (hover: hover) {
  .ui-container button:not([data-type=svg], [data-color=secondary], [data-color=cancel], [data-color=green], [data-color=transparent]):hover {
    background-color: var(--color-primary--hover, #00B4DB);
  }
}
.ui-container button:not([data-type=svg], [data-color=secondary], [data-color=cancel], [data-color=green], [data-color=transparent]):active {
  background-color: var(--color-primary--pressed, #0295B5);
}
.ui-container button:not([data-type=svg], [data-color=secondary], [data-color=cancel], [data-color=green], [data-color=transparent]):disabled {
  background-color: var(--color-primary--disabled, transparent);
}
@media (hover: hover) {
  .ui-container button[data-type=svg]:hover svg {
    fill: var(--color-primary--hover, #00B4DB);
  }
}
.ui-container button[data-type=svg]:active svg {
  fill: var(--color-primary--pressed, #0295B5);
}
.ui-container button[data-type=svg]:disabled svg {
  fill: var(--color-primary--disabled, transparent);
}
.ui-container button[data-color=secondary] {
  background-color: var(--color-secondary--default, #F11C5C);
}
@media (hover: hover) {
  .ui-container button[data-color=secondary]:hover {
    background-color: var(--color-secondary--hover, #E90F50);
  }
}
.ui-container button[data-color=secondary]:active {
  background-color: var(--color-secondary--pressed, #CD003D);
}
.ui-container button[data-color=cancel] {
  background-color: var(--color-cancel--default, #F11C5C);
}
@media (hover: hover) {
  .ui-container button[data-color=cancel]:hover {
    background-color: var(--color-cancel--hover, #FF2C6B);
  }
}
.ui-container button[data-color=cancel]:active {
  background-color: var(--color-cancel--pressed, #E00043);
}
.ui-container button[data-color=green] {
  background-color: var(--color-spin--default, #7BDB00);
}
@media (hover: hover) {
  .ui-container button[data-color=green]:hover {
    background-color: var(--color-spin--hover, #87F100);
  }
}
.ui-container button[data-color=green]:active {
  background-color: var(--color-spin--pressed, #6BBE00);
}
.ui-container button[data-color=transparent] {
  background-color: transparent;
}
@media (hover: hover) {
  .ui-container button[data-color=transparent]:hover {
    background-color: transparent;
  }
}
.ui-container button[data-color=transparent]:active {
  background-color: transparent;
}
.ui-container svg {
  width: 100%;
}
.ui-container__title-desc-col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: #FFFFFF;
}
.ui-container__title-desc-col > * {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}
.ui-container__title-desc-col > :first-child {
  font-size: 18px;
  line-height: 19px;
}
.ui-container__title-desc-col > :nth-child(2) {
  font-size: 32px;
  line-height: 39px;
}
.ui-container__safe_zone {
  height: 100%;
  position: relative;
  margin: 0 20px;
}
.ui-container__safe_zone > div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.ui-container__panel {
  background-color: var(--color-panel--default, rgba(3, 7, 80, 0.3));
  background-blend-mode: darken;
  mix-blend-mode: normal;
  box-shadow: inset 0 1px 0.5px rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translate3D(0, 0, 0);
  transform: translate3D(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.ui-container__bet_container {
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.ui-container__control_buttons {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%) translateZ(0);
  transform: translateY(-50%) translateZ(0);
}
.ui-container__control_buttons_container {
  position: relative;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 55px 32px;
}
.ui-container__control_buttons_container > * {
  width: 100%;
  display: flex;
}
.ui-container__control_buttons_container button {
  height: 96px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--color-primary--default, rgba(3, 7, 80, 0.8));
  background-blend-mode: darken;
  mix-blend-mode: normal;
  box-shadow: inset 0 1px 0.5px rgba(255, 255, 255, 0.1);
  aspect-ratio: 1/1;
}
.ui-container__control_buttons_container button[data-role=max-bet] {
  padding: 16px;
}
.ui-container__control_buttons_container button[data-role=max-bet] > div {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #FFFFFF;
  font-weight: 800;
}
.ui-container__control_buttons_container button[data-role=max-bet] > div > span {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.ui-container__control_buttons_container__stop_spin_container {
  position: relative;
  padding-top: 120%;
  padding-bottom: 64px;
  width: 100%;
  margin: 20px 0;
}
.ui-container__control_buttons_container__stop_spin_container > * {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui-container__control_buttons_container__stop_spin_container > * button {
  border: 10px solid #FFFFFF;
  position: relative;
  height: 100%;
  aspect-ratio: 1/1;
  padding: 0;
  border-radius: 50%;
  background-blend-mode: normal;
  box-shadow: none;
  z-index: 1;
}
.ui-container__control_buttons_container__stop_spin_container > * button svg {
  width: initial;
  height: initial;
}
.ui-container__control_buttons_container__stop_spin_container__spin svg {
  position: relative;
  top: -3px;
  left: -1px;
  -webkit-transform-origin: center 55%;
  transform-origin: center 55%;
}
.ui-container__control_buttons_container__stop_spin_container__spin div {
  position: absolute;
  top: 64%;
  left: 0;
  width: 100%;
  padding: 4px;
  background-color: #FFFFFF;
  z-index: 1;
  -webkit-clip-path: polygon(0 0, 100% 0, 95% 50%, 90% 100%, 10% 100%, 5% 50%);
  clip-path: polygon(0 0, 100% 0, 95% 50%, 90% 100%, 10% 100%, 5% 50%);
}
.ui-container__control_buttons_container__stop_spin_container__spin div span {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  font-family: "Inter", "Ubuntu bold", "sans-serif";
  color: var(--color-spin--default, #7BDB00);
}
.ui-container__control_buttons_container__stop_spin_container .freespins_count_shown:before {
  content: attr(data-freespins);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "Inter", "Ubuntu bold", "sans-serif";
  font-size: 48px;
  color: #FFFFFF;
  z-index: 2;
}
.ui-container__control_buttons_container__stop_spin_container__stop div {
  position: absolute;
  width: 50%;
  height: 50%;
  aspect-ratio: 1/1;
  border: 8px solid #FFFFFF;
  border-radius: 25px;
}
.ui-container__control_panels {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateZ(0);
  transform: translateX(-50%) translateZ(0);
}
.ui-container__control_panels_container {
  position: relative;
  height: 80px;
  display: flex;
  gap: 32px;
}
.ui-container__control_panels_container > * {
  display: flex;
  flex-direction: row;
}
.ui-container__control_panels_container > * button {
  border-radius: 20px;
  height: 100%;
  aspect-ratio: 1/1;
  padding: 0;
}
.ui-container__control_panels_container__main {
  width: 1138px;
  padding: 9px 48px;
  justify-content: space-between;
}
.ui-container__control_panels_container__main > * {
  display: flex;
  height: 100%;
  align-items: center;
}
.ui-container__control_panels_container__main .ui-container__bet_container {
  grid-template-columns: 64px 204px 64px;
}
.ui-container__control_panels_container__main .ui-container__win_container,
.ui-container__control_panels_container__main .ui-container__balance_container {
  width: 204px;
}
.ui-container__control_panels_container__sub {
  align-items: center;
  justify-content: center;
}
.ui-container__control_panels_container__sub > div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.ui-container__control_panels_container__sub button {
  width: 100%;
  height: 100%;
  padding: 7px;
}
.ui-container__control_panels_container__total_win {
  position: absolute;
  height: 120%;
  width: 203px;
  top: -10%;
  right: -36px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.ui-container__control_panels_container__total_win > * > * {
  padding: 18px 4px;
}
.ui-container__bet_selector_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.ui-container__bet_selector_container > * {
  width: 100%;
  height: 100%;
}
.ui-container__bet_selector_container > * > * {
  width: 100%;
  height: 100%;
}
.ui-container__bet_selector_container__title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
  font-size: 48px;
}
.ui-container__bet_selector_container__table {
  width: 100%;
  height: 100%;
  padding: 32px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui-container__bet_selector_container__table .ui-container__bet_selector_container__table__min_button,
.ui-container__bet_selector_container__table .ui-container__bet_selector_container__table__max_button {
  font-size: 48px;
  line-height: 58px;
}
.ui-container__bet_selector_container__table button {
  padding: 26px 14px;
  font-size: 48px;
  line-height: 58px;
  color: #FFFFFF;
  border-radius: 20px;
}
.ui-container__bet_selector_container__table button span {
  width: 100%;
  overflow: hidden;
}
.ui-container__bet_selector_container[data-orientation=landscape] > * {
  padding: 110px 160px 100px 0;
}
.ui-container__bet_selector_container[data-orientation=landscape] .ui-container__bet_selector_container__table button {
  width: 286px;
  height: 112px;
  border-radius: 24px;
}
.ui-container__bet_selector_container[data-orientation=landscape] .ui-container__bet_selector_container__table button span[data-font-ratio=small] {
  font-size: 36px;
}
.ui-container__bet_selector_container[data-orientation=landscape] .ui-container__bet_selector_container__table button span[data-font-ratio=big] {
  font-size: 48px;
}
.ui-container__bet_selector_container[data-orientation=landscape] .ui-container__bet_selector_container__table > * {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}
.ui-container__bet_selector_container[data-orientation=landscape] .ui-container__bet_selector_container__table > * > :first-child {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.ui-container__bet_selector_container[data-orientation=landscape] .ui-container__bet_selector_container__table > * > :nth-child(2) {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}
.ui-container__bet_selector_container[data-orientation=landscape] .ui-container__bet_selector_container__table > * > :nth-child(2) > * {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.ui-container__bet_selector_container[data-orientation=portrait] > * {
  padding: 110px 0 584px 0;
}
.ui-container__bet_selector_container[data-orientation=portrait] .ui-container__bet_selector_container__table button {
  width: 166px;
  height: 120px;
}
.ui-container__bet_selector_container[data-orientation=portrait] .ui-container__bet_selector_container__table button span[data-font-ratio=small] {
  font-size: 24px;
}
.ui-container__bet_selector_container[data-orientation=portrait] .ui-container__bet_selector_container__table button span[data-font-ratio=big] {
  font-size: 48px;
}
.ui-container__bet_selector_container[data-orientation=portrait] .ui-container__bet_selector_container__table > * {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
}
.ui-container__bet_selector_container[data-orientation=portrait] .ui-container__bet_selector_container__table > * > :first-child {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
}
.ui-container__bet_selector_container[data-orientation=portrait] .ui-container__bet_selector_container__table > * > :nth-child(2) {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
}
.ui-container__bet_selector_container[data-orientation=portrait] .ui-container__bet_selector_container__table > * > :nth-child(2) > * {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
}
.ui-container__tournaments_label_container {
  position: relative;
  top: 0;
  left: 0;
}
.ui-container__portrait {
  position: absolute;
  bottom: 0;
  left: 0;
}
.ui-container__portrait_container {
  position: relative;
  width: 100%;
  padding: 60px;
  height: 584px;
  display: grid;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  grid-template-rows: repeat(3, 120px);
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  justify-content: center;
}
.ui-container__portrait_container button {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 0;
}
@media (hover: hover) {
  .ui-container__portrait_container button[data-role=info]:hover {
    background-color: var(--color-primary--hover, #00B4DB);
  }
}
.ui-container__portrait_container button[data-role=info]:active {
  background-color: var(--color-primary--pressed, #0295B5);
}
.ui-container__portrait_container button[data-role=info]:disabled {
  background-color: var(--color-primary--disabled, transparent);
}
.ui-container__portrait_container > * {
  width: 100%;
  height: 100%;
}
.ui-container__portrait_container > :nth-child(1) {
  -ms-grid-column-span: 6;
  grid-column: span 6/span 6;
}
.ui-container__portrait_container > :nth-child(2) {
  -ms-grid-column-span: 6;
  grid-column: span 6/span 6;
}
.ui-container__portrait_container > :nth-child(3) {
  -ms-grid-column-span: 8;
  grid-column: span 8/span 8;
}
.ui-container__portrait_container > :nth-child(4) {
  -ms-grid-column-span: 3;
  grid-column: span 3/span 3;
}
.ui-container__portrait_container > :nth-child(5) {
  -ms-grid-column-span: 14;
  grid-column: span 14/span 14;
}
.ui-container__portrait_container > :nth-child(6) {
  -ms-grid-column-span: 3;
  grid-column: span 3/span 3;
}
.ui-container__portrait_container > :nth-child(7) {
  -ms-grid-column-span: 3;
  grid-column: span 3/span 3;
}
.ui-container__portrait_container > :nth-child(8) {
  -ms-grid-column-span: 4;
  grid-column: span 4/span 4;
}
.ui-container__portrait_container > :nth-child(9) {
  -ms-grid-column-span: 6;
  grid-column: span 6/span 6;
}
.ui-container__portrait_container .ui-container__panel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui-container__portrait_container .ui-container__panel > * {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui-container__portrait_container__spin_panel {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 25px 40px;
  height: 120%;
}
.ui-container__portrait_container__spin_panel .ui-container__panel button {
  background-color: var(--color-primary--default, rgba(3, 7, 80, 0.8));
}
.ui-container__portrait_container__spin_panel button {
  padding: 12px 0;
}
.ui-container__portrait_container__spin_panel > * {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ui-container__portrait_container__spin_panel > * > * {
  width: 100%;
  height: 100%;
}
.ui-container__portrait_container__spin_panel .ui-container__control_buttons_container__stop_spin_container {
  padding-top: 62%;
}
.ui-container__portrait_container__spin_panel .ui-container__control_buttons_container__stop_spin_container button {
  border-radius: 50%;
}
.ui-container__portrait_container__total_win_panel > * {
  height: 100%;
  display: flex;
}
.ui-container__portrait_container .ui-container__balance_container,
.ui-container__portrait_container .ui-container__total_win_container,
.ui-container__portrait_container .ui-container__win_container {
  padding: 27px 25px;
}
.ui-container__portrait_container .ui-container__bet_container {
  padding: 27px 20px;
  grid-template-columns: 56px 200px 56px;
}
.ui-container__portrait_container > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.ui-container__portrait_container > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.ui-container__portrait_container > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.ui-container__portrait_container > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}
.ui-container__portrait_container > *:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 9;
}
.ui-container__portrait_container > *:nth-child(6) {
  -ms-grid-row: 1;
  -ms-grid-column: 11;
}
.ui-container__portrait_container > *:nth-child(7) {
  -ms-grid-row: 1;
  -ms-grid-column: 13;
}
.ui-container__portrait_container > *:nth-child(8) {
  -ms-grid-row: 1;
  -ms-grid-column: 15;
}
.ui-container__portrait_container > *:nth-child(9) {
  -ms-grid-row: 1;
  -ms-grid-column: 17;
}
.ui-container__portrait_container > *:nth-child(10) {
  -ms-grid-row: 1;
  -ms-grid-column: 19;
}
.ui-container__portrait_container > *:nth-child(11) {
  -ms-grid-row: 1;
  -ms-grid-column: 21;
}
.ui-container__portrait_container > *:nth-child(12) {
  -ms-grid-row: 1;
  -ms-grid-column: 23;
}
.ui-container__portrait_container > *:nth-child(13) {
  -ms-grid-row: 1;
  -ms-grid-column: 25;
}
.ui-container__portrait_container > *:nth-child(14) {
  -ms-grid-row: 1;
  -ms-grid-column: 27;
}
.ui-container__portrait_container > *:nth-child(15) {
  -ms-grid-row: 1;
  -ms-grid-column: 29;
}
.ui-container__portrait_container > *:nth-child(16) {
  -ms-grid-row: 1;
  -ms-grid-column: 31;
}
.ui-container__portrait_container > *:nth-child(17) {
  -ms-grid-row: 1;
  -ms-grid-column: 33;
}
.ui-container__portrait_container > *:nth-child(18) {
  -ms-grid-row: 1;
  -ms-grid-column: 35;
}
.ui-container__portrait_container > *:nth-child(19) {
  -ms-grid-row: 1;
  -ms-grid-column: 37;
}
.ui-container__portrait_container > *:nth-child(20) {
  -ms-grid-row: 1;
  -ms-grid-column: 39;
}
.ui-container__portrait_container > *:nth-child(21) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}
.ui-container__portrait_container > *:nth-child(22) {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}
.ui-container__portrait_container > *:nth-child(23) {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
}
.ui-container__portrait_container > *:nth-child(24) {
  -ms-grid-row: 3;
  -ms-grid-column: 7;
}
.ui-container__portrait_container > *:nth-child(25) {
  -ms-grid-row: 3;
  -ms-grid-column: 9;
}
.ui-container__portrait_container > *:nth-child(26) {
  -ms-grid-row: 3;
  -ms-grid-column: 11;
}
.ui-container__portrait_container > *:nth-child(27) {
  -ms-grid-row: 3;
  -ms-grid-column: 13;
}
.ui-container__portrait_container > *:nth-child(28) {
  -ms-grid-row: 3;
  -ms-grid-column: 15;
}
.ui-container__portrait_container > *:nth-child(29) {
  -ms-grid-row: 3;
  -ms-grid-column: 17;
}
.ui-container__portrait_container > *:nth-child(30) {
  -ms-grid-row: 3;
  -ms-grid-column: 19;
}
.ui-container__portrait_container > *:nth-child(31) {
  -ms-grid-row: 3;
  -ms-grid-column: 21;
}
.ui-container__portrait_container > *:nth-child(32) {
  -ms-grid-row: 3;
  -ms-grid-column: 23;
}
.ui-container__portrait_container > *:nth-child(33) {
  -ms-grid-row: 3;
  -ms-grid-column: 25;
}
.ui-container__portrait_container > *:nth-child(34) {
  -ms-grid-row: 3;
  -ms-grid-column: 27;
}
.ui-container__portrait_container > *:nth-child(35) {
  -ms-grid-row: 3;
  -ms-grid-column: 29;
}
.ui-container__portrait_container > *:nth-child(36) {
  -ms-grid-row: 3;
  -ms-grid-column: 31;
}
.ui-container__portrait_container > *:nth-child(37) {
  -ms-grid-row: 3;
  -ms-grid-column: 33;
}
.ui-container__portrait_container > *:nth-child(38) {
  -ms-grid-row: 3;
  -ms-grid-column: 35;
}
.ui-container__portrait_container > *:nth-child(39) {
  -ms-grid-row: 3;
  -ms-grid-column: 37;
}
.ui-container__portrait_container > *:nth-child(40) {
  -ms-grid-row: 3;
  -ms-grid-column: 39;
}
.ui-container__portrait_container > *:nth-child(41) {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
}
.ui-container__portrait_container > *:nth-child(42) {
  -ms-grid-row: 5;
  -ms-grid-column: 3;
}
.ui-container__portrait_container > *:nth-child(43) {
  -ms-grid-row: 5;
  -ms-grid-column: 5;
}
.ui-container__portrait_container > *:nth-child(44) {
  -ms-grid-row: 5;
  -ms-grid-column: 7;
}
.ui-container__portrait_container > *:nth-child(45) {
  -ms-grid-row: 5;
  -ms-grid-column: 9;
}
.ui-container__portrait_container > *:nth-child(46) {
  -ms-grid-row: 5;
  -ms-grid-column: 11;
}
.ui-container__portrait_container > *:nth-child(47) {
  -ms-grid-row: 5;
  -ms-grid-column: 13;
}
.ui-container__portrait_container > *:nth-child(48) {
  -ms-grid-row: 5;
  -ms-grid-column: 15;
}
.ui-container__portrait_container > *:nth-child(49) {
  -ms-grid-row: 5;
  -ms-grid-column: 17;
}
.ui-container__portrait_container > *:nth-child(50) {
  -ms-grid-row: 5;
  -ms-grid-column: 19;
}
.ui-container__portrait_container > *:nth-child(51) {
  -ms-grid-row: 5;
  -ms-grid-column: 21;
}
.ui-container__portrait_container > *:nth-child(52) {
  -ms-grid-row: 5;
  -ms-grid-column: 23;
}
.ui-container__portrait_container > *:nth-child(53) {
  -ms-grid-row: 5;
  -ms-grid-column: 25;
}
.ui-container__portrait_container > *:nth-child(54) {
  -ms-grid-row: 5;
  -ms-grid-column: 27;
}
.ui-container__portrait_container > *:nth-child(55) {
  -ms-grid-row: 5;
  -ms-grid-column: 29;
}
.ui-container__portrait_container > *:nth-child(56) {
  -ms-grid-row: 5;
  -ms-grid-column: 31;
}
.ui-container__portrait_container > *:nth-child(57) {
  -ms-grid-row: 5;
  -ms-grid-column: 33;
}
.ui-container__portrait_container > *:nth-child(58) {
  -ms-grid-row: 5;
  -ms-grid-column: 35;
}
.ui-container__portrait_container > *:nth-child(59) {
  -ms-grid-row: 5;
  -ms-grid-column: 37;
}
.ui-container__portrait_container > *:nth-child(60) {
  -ms-grid-row: 5;
  -ms-grid-column: 39;
}
.ui-container .hidden {
  display: none !important;
}
.ui-container button.toggled_on {
  background-color: var(--color-toggled--default, #00B4DB);
}

.hidden {
  display: none !important;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: >0.3%,last 4 version,not dead
*/
.OPWrapper_service__notifications {
  position: relative;
  width: 100%;
  height: 100%;
  text-transform: initial;
  z-index: 1000;
}
.OPWrapper_service__notifications__wrapper {
  position: relative;
  height: 100%;
}
.OPWrapper_service__notifications__wrapper__notification {
  position: absolute;
  top: 20px;
  right: 20px;
}

.system-ui-container__notifications__tournament_notification {
  top: 100px;
  width: 605px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 26px;
  gap: 24px;
  border-radius: 24px;
  pointer-events: auto;
  background-color: var(--color-notification-tournament--default, var(--color-notification-system--default, rgba(33, 30, 52, 0.8)));
  -webkit-animation: appearance-right-to-left 0.5s ease-out;
  animation: appearance-right-to-left 0.5s ease-out;
  cursor: pointer;
}
.system-ui-container__notifications__tournament_notification.notification_hide {
  -webkit-animation: hiding-left-to-right 0.5s ease-in;
  animation: hiding-left-to-right 0.5s ease-in;
}
@-webkit-keyframes appearance-right-to-left {
  0% {
    -webkit-transform: translateX(calc(100% + 20px));
    transform: translateX(calc(100% + 20px));
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes appearance-right-to-left {
  0% {
    -webkit-transform: translateX(calc(100% + 20px));
    transform: translateX(calc(100% + 20px));
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes hiding-left-to-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(100% + 20px));
    transform: translateX(calc(100% + 20px));
  }
}
@keyframes hiding-left-to-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(100% + 20px));
    transform: translateX(calc(100% + 20px));
  }
}
.system-ui-container__notifications__tournament_notification__title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.system-ui-container__notifications__tournament_notification__title h1 {
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #F2F2F2;
}
.system-ui-container__notifications__tournament_notification__content {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.system-ui-container__notifications__tournament_notification__content > * {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  padding: 0 8px;
}
.system-ui-container__notifications__tournament_notification__content > * > div {
  padding: 0 2px;
  display: grid;
  align-items: center;
  text-align: center;
}
.system-ui-container__notifications__tournament_notification__content > * > div > span {
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.system-ui-container__notifications__tournament_notification__content > :first-child {
  opacity: 0.6;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.system-ui-container__notifications__tournament_notification__content > :last-child {
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: #FF4155;
  border: 2px solid #ED1132;
  border-radius: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: >0.3%,last 4 version,not dead
*/
.system-ui-container__sidebar_overlay {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: all;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 523px;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar {
  height: 100%;
  width: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: var(--color-system-sidebar-background--default, #29263C);
  border-radius: 10px 10px 0 0;
  transition: 0.5s;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  font-size: 48px;
  font-weight: 600;
  color: #FFFFFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  overflow: hidden;
  padding: 40px 30px;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button {
  padding: 28px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 40px;
  font-weight: 700;
  color: #FFFFFF;
}
@media (hover: hover) {
  .system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button:has(.icon.tournament) {
  align-items: flex-start;
  padding: 10px 109px 10px 0;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button:has(.icon.tournament) .icon {
  width: 72px;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button:has(.icon.tournament) > span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button:has(.icon.tournament) > span span {
  color: transparent;
  background: -webkit-linear-gradient(-90deg, #F79F2D 0%, #FF4D4D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.rules {
  background: url(assets/images/56d788c29d02e2265ab5431c6f6c2df0.svg) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.leaderboard {
  background: url(assets/images/864f41150cd15618b01d8fe83517cd6c.png) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.info {
  background: url(assets/images/9f03bb8371fc1392542e667c75f8da75.svg) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.gameLimits {
  background: url(assets/images/a4236f57fbfc83d0cbae4b3a8bfbd051.svg) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.settings {
  background: url(assets/images/a2a3a5e0f93e4a2b337704ed44941df8.svg) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.games {
  background: url(assets/images/429743554800729cdf56465d5b0f8e41.svg) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.feedback {
  background: url(assets/images/ef98984adbc48de34e38147701e4610c.svg) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.tournament {
  background: url(assets/images/43a6c41f6d4cabfdc4cdfcbeff483aa1.svg) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.home {
  background: url(assets/images/b1adcee4667ece9f5d89831ee57e69c7.png) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.soundOn {
  background: url(assets/images/6310b9b1de7460d3afa7a078f5d738d4.png) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body_content button .icon.soundOff {
  background: url(assets/images/ef297bf711a01668e3ce9c60c031a8e9.png) no-repeat center;
  background-size: 100%;
}
.system-ui-container__sidebar_overlay .system-ui-container__sidebar_wrapper .system-ui-container__sidebar__body .sidebar_gameVersion {
  flex-shrink: 0;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: >0.3%,last 4 version,not dead
*/
.system-ui-container__tournaments {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 60px 20px 0;
  font-family: "Inter", "sans-serif";
  pointer-events: auto;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments {
    padding: 40px;
  }
}
.system-ui-container__tournaments button.primary {
  background-color: var(--color-tournament-primary--default, #17142A);
}
@media (hover: hover) {
  .system-ui-container__tournaments button.primary:hover {
    background-color: var(--color-tournament-primary--hover, #211E34);
  }
}
.system-ui-container__tournaments button.primary:active {
  background-color: var(--color-tournament-primary--pressed, #211E34);
}
.system-ui-container__tournaments button.primary:disabled {
  background-color: var(--color-tournament-primary--disabled, #312E42);
  color: var(--color-tournament-primary-text--non-active--default, #7F7D8A);
}
.system-ui-container__tournaments button.secondary {
  background-color: var(--color-tournament-secondary--default, #537AE3);
}
@media (hover: hover) {
  .system-ui-container__tournaments button.secondary:hover {
    background-color: var(--color-tournament-secondary--hover, #709AEA);
  }
}
.system-ui-container__tournaments button.secondary:active {
  background-color: var(--color-tournament-secondary--pressed, #3147C4);
}
.system-ui-container__tournaments button.secondary:disabled {
  background-color: var(--color-tournament-secondary--disabled, rgba(45, 59, 160, 0.6));
  color: var(--color-tournament-secondary-text--non-active--default, #DFE8FA);
}
.system-ui-container__tournaments__spinner {
  display: inline-block;
  position: relative;
  width: 174px;
  height: 174px;
}
.system-ui-container__tournaments__spinner div {
  -webkit-transform-origin: 87px 87px;
  transform-origin: 87px 87px;
  -webkit-animation: tournament-spinner 1.6s linear infinite;
  animation: tournament-spinner 1.6s linear infinite;
}
.system-ui-container__tournaments__spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #FF971D;
}
.system-ui-container__tournaments__spinner.small {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.system-ui-container__tournaments__spinner.small > div {
  -webkit-transform-origin: unset;
  transform-origin: unset;
}
.system-ui-container__tournaments__spinner.small > div:after {
  left: 18px;
  top: 18px;
  width: 10px;
  height: 10px;
}
.system-ui-container__tournaments__spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}
.system-ui-container__tournaments__spinner div:nth-child(2) {
  -webkit-transform: rotate(22.5deg);
  transform: rotate(22.5deg);
  -webkit-animation-delay: -1.4s;
  animation-delay: -1.4s;
}
.system-ui-container__tournaments__spinner div:nth-child(3) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation-delay: -1.3s;
  animation-delay: -1.3s;
}
.system-ui-container__tournaments__spinner div:nth-child(4) {
  -webkit-transform: rotate(67.5deg);
  transform: rotate(67.5deg);
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.system-ui-container__tournaments__spinner div:nth-child(5) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.system-ui-container__tournaments__spinner div:nth-child(6) {
  -webkit-transform: rotate(112.5deg);
  transform: rotate(112.5deg);
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.system-ui-container__tournaments__spinner div:nth-child(7) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.system-ui-container__tournaments__spinner div:nth-child(8) {
  -webkit-transform: rotate(157.5deg);
  transform: rotate(157.5deg);
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.system-ui-container__tournaments__spinner div:nth-child(9) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.system-ui-container__tournaments__spinner div:nth-child(10) {
  -webkit-transform: rotate(202.5deg);
  transform: rotate(202.5deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.system-ui-container__tournaments__spinner div:nth-child(11) {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.system-ui-container__tournaments__spinner div:nth-child(12) {
  -webkit-transform: rotate(247.5deg);
  transform: rotate(247.5deg);
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.system-ui-container__tournaments__spinner div:nth-child(13) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.system-ui-container__tournaments__spinner div:nth-child(14) {
  -webkit-transform: rotate(292.5deg);
  transform: rotate(292.5deg);
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.system-ui-container__tournaments__spinner div:nth-child(15) {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
.system-ui-container__tournaments__spinner div:nth-child(16) {
  -webkit-transform: rotate(337.5deg);
  transform: rotate(337.5deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.system-ui-container__tournaments .tournament_logo {
  display: block;
}
.system-ui-container__tournaments__stat_list {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
  pointer-events: auto;
  touch-action: pan-y;
}
.system-ui-container__tournaments__stat_row {
  background-color: var(--color-tournament-primary--default, #17142A);
  padding: 28px 40px;
  border-radius: 16px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 30px;
}
.system-ui-container__tournaments__stat_row .system-ui-container__tournaments__stat_cell:last-of-type {
  color: #FFFFFF;
}
.system-ui-container__tournaments__stat_cell {
  color: rgba(255, 255, 255, 0.5);
}
.system-ui-container__tournaments_main {
  position: relative;
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 42px;
  border-radius: 12px;
  background-color: var(--color-tournament-primary--default, #17142A);
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main {
    grid-template-rows: auto 1fr;
    gap: 40px;
  }
}
.system-ui-container__tournaments_main__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 34px 40px 0;
  z-index: 11;
}
.system-ui-container__tournaments_main__header > :nth-child(2) {
  display: flex;
  gap: 40px;
}
.system-ui-container__tournaments_main__header > :nth-child(2) .system-ui-container__stroke_button {
  padding: 8px 30px;
  font-size: 30px;
  font-weight: 500;
}
.system-ui-container__tournaments_main__preloader {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 70px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-tournament-primary--default, #17142A);
  border-radius: 12px;
  z-index: 10;
}
.system-ui-container__tournaments_main__preloader span {
  font-weight: 700;
  font-size: 40px;
  color: #FFFFFF;
  text-align: center;
}
.system-ui-container__tournaments_main__title {
  padding: 0 40px;
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__title {
    display: none;
  }
}
.system-ui-container__tournaments_main__body {
  padding: 0 40px 0;
  display: grid;
  grid-template-rows: auto 100%;
  overflow: scroll;
  gap: 40px;
  pointer-events: auto;
  touch-action: pan-y;
}
.system-ui-container__tournaments_main__body * {
  pointer-events: none;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__body {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
  }
}
.system-ui-container__tournaments_main__body_description {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.system-ui-container__tournaments_main__body_description_title {
  display: none;
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__body_description_title {
    display: block;
    font-size: 40px;
    text-align: left;
  }
}
.system-ui-container__tournaments_main__body_description_promo_block {
  display: grid;
  grid-template-rows: 382px auto;
  gap: 24px;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__body_description_promo_block {
    grid-template-rows: 412px 232px auto;
  }
}
.system-ui-container__tournaments_main__body_description_promo_block > * {
  border-radius: 24px;
  overflow: hidden;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box {
  height: 100%;
  position: relative;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box__end_label {
  position: absolute;
  top: 24px;
  left: 24px;
  padding: 11px 13px;
  background-color: var(--color-tournament-panel-background--default, #2B334F);
  border: 3px solid var(--color-tournament-panel-stroke--default, #F79F2D);
  border-radius: 16px;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 700;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 38, 60, 0.8);
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay__ends_block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 32px;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay__ends_block > div {
  display: flex;
  gap: 12px;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay__ends_block > div > div {
  display: flex;
  align-items: flex-end;
  font-size: 32px;
  line-height: 39px;
  font-weight: 600;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay__ended_block {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 24px;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay span {
  white-space: pre-line;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 28px;
  line-height: 34px;
}
.system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay span:first-of-type {
  font-size: 32px;
  line-height: 39px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details {
  position: relative;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: grid;
  grid-template-rows: 120px;
  grid-auto-rows: 112px;
  background-color: var(--color-tournament-panel-background--default, #2B334F);
  border-radius: 24px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details::after {
  content: "";
  position: absolute;
  inset: 0 0 0 0;
  border-radius: inherit;
  border: 3px solid var(--color-tournament-panel-stroke--default, #F79F2D);
  pointer-events: none;
}
.system-ui-container__tournaments_main__body_description_promo_block__details.active {
  background-color: #ED1132;
}
.system-ui-container__tournaments_main__body_description_promo_block__details.active::after {
  border: 3px solid transparent;
}
.system-ui-container__tournaments_main__body_description_promo_block__details.active > div:nth-child(1) {
  background-color: #FF4155;
}
.system-ui-container__tournaments_main__body_description_promo_block__details.active > div:nth-child(2) {
  display: flex;
}
.system-ui-container__tournaments_main__body_description_promo_block__details.active .system-ui-container__tournaments_main__body_description_promo_block__details_title > span {
  font-size: 38px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details.active .system-ui-container__tournaments_main__body_description_promo_block__details_title > svg > #clock_shadow {
  display: block;
}
.system-ui-container__tournaments_main__body_description_promo_block__details.active .system-ui-container__tournaments_main__body_description_promo_block__details_timer {
  display: flex;
}
.system-ui-container__tournaments_main__body_description_promo_block__details > div:nth-child(1) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 17px 30px;
  background-color: transparent;
  border-radius: 24px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_title {
  display: flex;
  flex-direction: column;
  padding-left: 101px;
  isolation: isolate;
  color: #FFFFFF;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_title svg {
  position: absolute;
  bottom: -1px;
  left: 40px;
  z-index: -1;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_title svg #clock_shadow {
  display: none;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_title > span {
  font-weight: 800;
  font-size: 47px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_title > div {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_title > div > span:nth-child(1) {
  color: #FFB638;
  font-weight: 800;
  font-size: 48px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_title > div > span:nth-child(2) {
  font-weight: 600;
  font-size: 32px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details button {
  width: 252px;
  padding: 26px 30px;
  font-weight: 600;
  font-size: 28px;
  color: #FFFFFF;
  border-radius: 24px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details > div:nth-child(2) {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 16px 30px 23px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_timer {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  color: #FFFFFF;
  font-weight: 600;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_timer > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 77px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_timer > div > span:first-child {
  font-size: 38px;
}
.system-ui-container__tournaments_main__body_description_promo_block__details_timer > div > span:nth-child(2) {
  font-size: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.system-ui-container__tournaments_main__body_description_promo_block__details_timer > span {
  position: relative;
  font-size: 32px;
  line-height: 50px;
}
.system-ui-container__tournaments_main__body_description_promo_block__company {
  display: none;
  gap: 16px;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__body_description_promo_block__company {
    display: flex;
    align-items: center;
  }
}
.system-ui-container__tournaments_main__body_description_promo_block__company > span {
  color: #FFFFFF;
  font-size: 26px;
  font-weight: 500;
}
.system-ui-container__tournaments_main__body_content {
  display: grid;
  overflow: hidden;
}
.system-ui-container__tournaments_main__body_content > div {
  overflow: hidden;
}
.system-ui-container__tournaments_main__body_content__stat {
  min-height: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
}
.system-ui-container__tournaments_main__body_content__stat_tabs {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
.system-ui-container__tournaments_main__body_content__stat_tabs button {
  padding: 31px 4px;
  border-radius: 24px 24px 0 0;
  font-weight: 500;
  font-size: 28px;
  color: #7F7D8A;
  transition: color 0.2s;
}
.system-ui-container__tournaments_main__body_content__stat_tabs button.active {
  background-color: #211E34;
  font-weight: 600;
  color: #FFFFFF;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__body_content__stat_tabs button {
    padding: 20px 4px;
  }
}
.system-ui-container__tournaments_main__body_content__stat_player {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 16px 40px;
  background-color: #211E34;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__body_content__stat_player {
    padding: 10px 40px;
  }
}
.system-ui-container__tournaments_main__body_content__stat_player > div {
  border-radius: 16px;
  border: 3px solid transparent;
  padding: 14px 16px;
  font-weight: 700;
  font-size: 30px;
  color: #FFFFFF;
}
.system-ui-container__tournaments_main__body_content__stat_player.active > div {
  border: 3px solid var(--color-tournament-panel-stroke--default, #F79F2D);
  background-color: var(--color-tournament-panel-background--default, #2B334F);
}
.system-ui-container__tournaments_main__body_content__stat_view {
  display: grid;
  background-color: #211E34;
  overflow: hidden;
}
.system-ui-container__tournaments_main__body_content__stat_view.active .system-ui-container__tournaments__stat_cell {
  color: #FFFFFF;
}
.system-ui-container__tournaments_main__body_content__stat_view table {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  overflow: hidden;
}
.system-ui-container__tournaments_main__body_content__stat_view table thead {
  font-weight: 700;
  font-size: 30px;
  color: #FFFFFF;
}
.system-ui-container__tournaments_main__body_content__stat_view table thead tr {
  padding: 32px 24px;
}
.system-ui-container__tournaments_main__body_content__stat_view table tr {
  flex-shrink: 0;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}
.system-ui-container__tournaments_main__body_content__stat_view table th, .system-ui-container__tournaments_main__body_content__stat_view table td {
  display: grid;
  text-align: center;
  padding: 0 2px;
}
.system-ui-container__tournaments_main__body_content__stat_view table th span, .system-ui-container__tournaments_main__body_content__stat_view table td span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard thead {
  padding: 24px 24px 0;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody {
  padding: 0 24px;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody:first-of-type {
  pointer-events: auto;
  touch-action: pan-y;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody + tbody {
  margin-top: 20px;
  padding-bottom: 24px;
  overflow: hidden;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody tr.placeholder {
  display: none;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody[data-has-more-items] tr.placeholder {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 39px 86px;
  gap: 16px;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody[data-has-more-items] tr.placeholder td {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody[data-has-more-items] tr.placeholder td:first-child span {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 10px;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody[data-has-more-items] tr.placeholder td:last-child button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-weight: 600;
  font-size: 28px;
  color: #FFFFFF;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody[data-has-more-items] tr.placeholder td:last-child button > :nth-child(2) {
  display: none;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody[data-has-more-items] tr.placeholder td:last-child button[data-loading] > :nth-child(1) {
  display: none;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tbody[data-has-more-items] tr.placeholder td:last-child button[data-loading] > :nth-child(2) {
  display: flex;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tr {
  grid-template-columns: 2fr 3fr 3fr 3fr;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard tr.player {
  box-shadow: inset 0 0 0 3px var(--color-tournament-panel-stroke--default, #F79F2D);
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard td:first-child {
  text-align: left;
}
.system-ui-container__tournaments_main__body_content__stat_view table.tournament_leaderboard td:last-child {
  color: #F5C827;
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_rules {
  padding: 24px 40px;
  overflow-y: scroll;
  pointer-events: auto;
  touch-action: pan-y;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__body_content__stat_view .tournament_rules {
    padding: 24px;
  }
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_rules p {
  font-weight: 500;
  font-size: 30px;
  line-height: 170%;
  color: #FFFFFF;
  white-space: pre-line;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_main__body_content__stat_view .tournament_rules p {
    font-size: 28px;
    line-height: 180%;
  }
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_nominations {
  padding: 24px;
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_prizes {
  padding: 24px 0;
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_prizes .system-ui-container__tournaments__stat_list {
  padding: 0 24px;
  pointer-events: auto;
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_nominations thead th:first-child,
.system-ui-container__tournaments_main__body_content__stat_view .tournament_prizes thead th:first-child {
  text-align: left;
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_nominations thead th:last-child,
.system-ui-container__tournaments_main__body_content__stat_view .tournament_prizes thead th:last-child {
  text-align: right;
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_nominations .system-ui-container__tournaments__stat_row,
.system-ui-container__tournaments_main__body_content__stat_view .tournament_prizes .system-ui-container__tournaments__stat_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-ui-container__tournaments_main__body_content__stat_view .tournament_nominations .system-ui-container__tournaments__stat_row .system-ui-container__tournaments__stat_cell:first-child,
.system-ui-container__tournaments_main__body_content__stat_view .tournament_prizes .system-ui-container__tournaments__stat_row .system-ui-container__tournaments__stat_cell:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.system-ui-container__tournaments_archive {
  position: absolute;
  inset: 0 0 0 0;
  z-index: 11;
}
.system-ui-container__tournaments_archive > div {
  grid-template-rows: auto auto 1fr;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_archive_title {
    display: block;
    font-size: 40px;
    text-align: left;
  }
}
.system-ui-container__tournaments_archive_list {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  align-content: flex-start;
  align-content: flex-start;
  overflow: scroll;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_archive_list {
    grid-template-columns: repeat(2, 1fr);
  }
}
.system-ui-container__tournaments_archive_list__card {
  width: 798px;
  height: 382px;
  display: grid;
  grid-template-rows: 1fr auto;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_archive_list__card {
    width: 860px;
    height: 412px;
  }
}
.system-ui-container__tournaments_archive_list__card_banner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
}
.system-ui-container__tournaments_archive_list__card_banner_overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 12px 24px;
  background-color: rgba(41, 38, 60, 0.8);
  color: #FFFFFF;
  font-weight: 600;
}
.system-ui-container__tournaments_archive_list__card_banner_overlay > span {
  white-space: pre-line;
  font-size: 28px;
  line-height: 34px;
}
.system-ui-container__tournaments_archive_list__card_banner_overlay > span:first-of-type {
  flex-shrink: 0;
  font-size: 32px;
  line-height: 39px;
}
.system-ui-container__tournaments_archive_list__card_banner_overlay button {
  flex-shrink: 0;
  width: 252px;
  padding: 24px 16px;
  border-radius: 14px;
  color: #FFFFFF;
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}
.system-ui-container__tournaments_archive_list__card_banner_label {
  position: absolute;
  top: 24px;
  left: 24px;
  padding: 11px 13px;
  background-color: var(--color-tournament-panel-background--default, #2B334F);
  border: 3px solid var(--color-tournament-panel-stroke--default, #F79F2D);
  border-radius: 16px;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 700;
}
@-webkit-keyframes tournament-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes tournament-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.system-ui-container__tournaments__modal__rules {
  height: 1070px;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments__modal__rules.system-ui-container__tournaments__modal__rules {
    width: 860px;
    height: 840px;
  }
}

.system-ui-container__tournaments__modal__tournament_end {
  height: 874px;
}

.system-ui-container__tournaments__modal__rules,
.system-ui-container__tournaments__modal__tournament_end {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 750px;
  border-radius: 24px;
  background-color: #FFFFFF;
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__rules__header,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__rules__header {
  padding: 26px 45px 26px 104px;
  font-size: 56px;
  isolation: isolate;
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__rules__header > svg,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__rules__header > svg {
  position: absolute;
  left: 40px;
  bottom: 0;
  z-index: -1;
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__header,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__header {
  height: 120px;
  padding: 12px 40px;
  font-size: 40px;
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__header > div,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__header > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.system-ui-container__tournaments__modal__rules__header,
.system-ui-container__tournaments__modal__tournament_end__header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-tournament-danger--default, #FF4155);
  color: #FFFFFF;
  font-weight: 600;
}
.system-ui-container__tournaments__modal__rules__header button.system-ui-container__close_button svg path:first-child,
.system-ui-container__tournaments__modal__tournament_end__header button.system-ui-container__close_button svg path:first-child {
  fill: var(--color-tournament-danger--default, #FF4155) !important;
}
@media (hover: hover) {
  .system-ui-container__tournaments__modal__rules__header button.system-ui-container__close_button:hover svg path:first-child,
.system-ui-container__tournaments__modal__tournament_end__header button.system-ui-container__close_button:hover svg path:first-child {
    fill: var(--color-tournament-danger--hover, #ED1132) !important;
  }
}
.system-ui-container__tournaments__modal__rules__header button.system-ui-container__close_button:active svg path:first-child,
.system-ui-container__tournaments__modal__tournament_end__header button.system-ui-container__close_button:active svg path:first-child {
  fill: var(--color-tournament-danger--pressed, #ED1132) !important;
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__body,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__body {
  display: grid;
  grid-template-rows: 1fr auto;
  padding: 40px 0;
  gap: 40px;
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__body b,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__body b {
  font-weight: 700;
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__body button,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__body button {
  padding: 43px 16px;
  margin: 0 40px;
  font-size: 38px;
  line-height: 38px;
  font-weight: 800;
  color: #FFFFFF;
  border-radius: 24px;
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__body button.secondary,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__body button.secondary {
  background-color: var(--color-tournament-secondary--default, #537AE3);
}
@media (hover: hover) {
  .system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__body button.secondary:hover,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__body button.secondary:hover {
    background-color: var(--color-tournament-secondary--hover, #709AEA);
  }
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__body button.secondary:active,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__body button.secondary:active {
  background-color: var(--color-tournament-secondary--pressed, #3147C4);
}
.system-ui-container__tournaments__modal__rules .system-ui-container__tournaments__modal__tournament_end__body button.secondary:disabled,
.system-ui-container__tournaments__modal__tournament_end .system-ui-container__tournaments__modal__tournament_end__body button.secondary:disabled {
  background-color: var(--color-tournament-secondary--disabled, rgba(45, 59, 160, 0.6));
  color: var(--color-tournament-secondary-text--non-active--default, #DFE8FA);
}
.system-ui-container__tournaments__modal__rules__body,
.system-ui-container__tournaments__modal__tournament_end__body {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 68px 0 40px;
  overflow: hidden;
  color: var(--color-tournament-primary--default, #17142A);
}
.system-ui-container__tournaments__modal__rules__body_content,
.system-ui-container__tournaments__modal__tournament_end__body_content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 40px;
  overflow: scroll;
  touch-action: pan-y;
  pointer-events: auto;
}
.system-ui-container__tournaments__modal__rules__body_content *,
.system-ui-container__tournaments__modal__tournament_end__body_content * {
  pointer-events: none;
}
.system-ui-container__tournaments__modal__rules__body_content h1,
.system-ui-container__tournaments__modal__tournament_end__body_content h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}
.system-ui-container__tournaments__modal__rules__body_content p,
.system-ui-container__tournaments__modal__tournament_end__body_content p {
  font-weight: 500;
  font-size: 30px;
  line-height: 170%;
  white-space: pre-line;
}

.ui-container__tournaments_label {
  position: relative;
  width: 100%;
  height: 100%;
}
.ui-container__tournaments_label button {
  position: absolute;
  right: 22px;
  padding: 21px;
  border: 3px solid #FF4155;
  border-radius: 24px;
}
.ui-container__tournaments_label button.primary {
  background-color: var(--color-tournament-label-background--default, #211E34);
}
@media (hover: hover) {
  .ui-container__tournaments_label button.primary:hover {
    background-color: var(--color-tournament-label-background--hover, #17142A);
  }
}
.ui-container__tournaments_label button.primary:active {
  background-color: var(--color-tournament-label-background--pressed, #17142A);
}
.ui-container__tournaments_label button.primary:disabled {
  background-color: var(--color-tournament-label-background--disabled, #312E42);
  border-color: var(--color-tournament-label-background--disabled, #312E42);
  opacity: 0.8;
}
.ui-container__tournaments_label button.primary:disabled svg {
  opacity: 0.6;
}

.ui-container__tournaments_label_container[data-orientation=landscape] .ui-container__tournaments_label button {
  top: 10px;
}

.ui-container__tournaments_label_container[data-orientation=portrait] .ui-container__tournaments_label button {
  top: 209px;
}

@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main {
    grid-template-rows: auto auto 1fr;
  }
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__title {
    display: inline-block;
    font-size: 40px;
    text-align: left;
  }
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body {
  gap: 40px;
  padding-bottom: 140px;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body {
    gap: 40px;
    padding-bottom: 40px;
    grid-template-rows: 1fr;
  }
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_description_promo_block {
    grid-template-rows: 382px auto;
  }
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content {
  grid-template-rows: 1fr auto;
  align-content: space-between;
  gap: 20px;
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
  pointer-events: auto;
  touch-action: pan-y;
  margin: 0 auto;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__info {
    width: 100%;
    margin: unset;
  }
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__info > span {
  margin-top: 20px;
  font-size: 36px;
  color: #FFFFFF;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__info > span {
    font-size: 32px;
  }
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__info ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #FFFFFF;
  font-size: 32px;
  line-height: 34px;
  font-weight: 500;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__info ul {
    font-size: 28px;
    line-height: 30px;
    margin: unset;
  }
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__info ul li {
  display: flex;
  align-items: center;
  gap: 16px;
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__info ul li svg {
  flex-shrink: 0;
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__submit_container {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
@media only screen and (orientation: landscape) {
  .system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__submit_container {
    gap: 36px;
  }
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__submit_container__warning {
  display: flex;
  align-items: center;
  padding: 11px 13px;
  gap: 10px;
  background-color: var(--color-tournament-panel-background--default, #2B334F);
  border: 3px solid var(--color-tournament-panel-stroke--default, #F79F2D);
  border-radius: 16px;
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 500;
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__submit_container__warning svg {
  flex-shrink: 0;
}
.system-ui-container__tournaments_start_screen .system-ui-container__tournaments_main__body_content .system-ui-container__tournaments_start_screen__submit_container button {
  padding: 33px 16px;
  border-radius: 24px;
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 800;
}