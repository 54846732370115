.opWrapper .sideBar {
  position: fixed;
  height: 100%;
  width: 14em;
  background: rgb(44,44,51);
  background: linear-gradient(90deg, rgba(44,44,51,1) 0%, rgba(59,60,87,1) 100%);
  transition: transform 500ms;
  pointer-events: all;
}

.opWrapper .hideSideBar {
  transform: translate(-100%, 0);
}

.opWrapper .container {
  min-height: 550px;
  padding: 1.2rem !important;
  margin: auto !important;
  top: 50%;
  position: relative;
  transform: translate(0, -50%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.opWrapper .buttonOval {
  cursor: pointer;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(255, 185, 0, 1) 0%, rgba(252, 89, 119, 1) 55%, rgba(231, 0, 255, 1) 100%);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.close {
  position: relative;
  width: max-content;
  margin-left: 16px;
  padding: 5px 12px;
}

.buttonOval:hover {
  opacity: 0.8;
}

.buttonOval:active {
  opacity: 1;
}

.buttonsContainer {
  margin-top: 1.5em;
  margin-left: 30px;
  margin-right: 30px;
}

.buttonsContainer .buttonContainer {
  display: flex;
  align-items: center;
  position: relative;
  color: inherit;
  padding-bottom: 0.7em;
  padding-top: 1em;
  pointer-events: all;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.buttonContainer .icon {
  height: 2em;
  width: 2em;
}

@media only screen and (max-height: 480px) {
  .opWrapper .container {
    justify-content: center;
  }

  .buttonsContainer {
    margin-top: 0.5em;
  }

  .buttonsContainer .buttonContainer {
    padding: 0.5em 0 0.5em 0;
  }

  .buttonsContainer .buttonContainer_s {
    padding: 0.2em 0 0.3em 0 !important;
  }

  .buttonContainer_s .icon {
    height: 1.2em !important;
    width: 1.2em !important;
  }

  .buttonContainer .icon {
    height: 1.3em;
    width: 1.3em;
  }
}

/*.icon.rules {*/
/*  background: url("../assets/images/buttons/rules.png") no-repeat center;*/
/*  background-size: 100%;*/
/*}*/

/*.icon.leaderboard {*/
/*  background: url("../assets/images/buttons/leaderboard.png") no-repeat center;*/
/*  background-size: 100%;*/
/*}*/

/*.icon.info {*/
/*  background: url("../assets/images/buttons/paytable.png") no-repeat center;*/
/*  background-size: 100%;*/
/*}*/

/*.icon.gameLimits {*/
/*  background: url("../assets/images/buttons/limits.png") no-repeat center;*/
/*  background-size: 100%;*/
/*}*/

/*.icon.settings {*/
/*  background: url("../assets/images/buttons/settings.png") no-repeat center;*/
/*  background-size: 100%;*/
/*}*/

/*.icon.home {*/
/*  background: url("../assets/images/buttons/home.png") no-repeat center;*/
/*  background-size: 100%;*/
/*}*/

/*.icon.soundOn {*/
/*  background: url("../assets/images/buttons/soundOn.png") no-repeat center;*/
/*  background-size: 100%;*/
/*}*/

/*.icon.soundOff {*/
/*  background: url("../assets/images/buttons/soundOff.png") no-repeat center;*/
/*  background-size: 100%;*/
/*}*/

.buttonContainer .separator {
  background: url(assets/images/29d64d6a0c438fa153886a578d9afa0b.png) no-repeat center;
  background-size: 100%;
  height: 0.2em;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.buttonContainer .text {
  margin-left: 0.3em;
}

@media (hover: hover) {
  .buttonContainer:hover {
    opacity: 0.8;
  }
}

.buttonContainer:active {
  opacity: 1;
}

.opWrapper .sideBar #gameVersion {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}


