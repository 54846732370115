@font-face {
  font-family: "League Gothic";
  src: url(assets/fonts/LEAGUEGOTHIC.TTF);
}
@font-face {
  font-family: "MyriadProBold";
  src: url(assets/fonts/MyriadProBold.otf);
}
@font-face {
  font-family: "UbuntuRegular";
  src: url(assets/fonts/UBUNTU-R.TTF);
}
@font-face {
  font-family: "UbuntuLight";
  src: url(assets/fonts/UBUNTU-L.TTF);
}
@font-face {
  font-family: "Ubuntu bold";
  src: url(assets/fonts/UBUNTU-B.TTF);
}
@font-face {
  font-family: "Bebas Neue";
  src: url(assets/fonts/BEBASNEUE-REGULAR.OTF);
}
html {
  touch-action: none;
}

* {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.opWrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  pointer-events: none;
  font-family: "Ubuntu bold", sans-serif;
  font-size: 22px;
  color: #FFFFE1;
  z-index: 200;
}

.opWrapper.enable {
  pointer-events: all;
}

@media only screen and (max-width: 700px) {
  .opWrapper {
    font-size: 16px;
  }

  .opWrapper .container {
    min-height: 300px;
  }
}
@media only screen and (max-height: 550px) {
  .opWrapper .container {
    min-height: 300px;
  }
}