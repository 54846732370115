#OPFullscreenButton {
  position: fixed;
  bottom: 1vh;
  right: 1vw;
  opacity: 0.6;
  transition: opacity 0.3s;
  pointer-events: all;
  z-index: 17;
}

#OPFullscreenButton.transparent {
  opacity: 0.3;
}

#OPFullscreenButton.hide {
  display: none;
}

#OPFullscreenButton:active {
  opacity: 1;
}

#OPFullscreenButton_image {
  width: max(5vw, 7vh);
  height: max(5vw, 7vh);
}

