.opInfoPanel {
  position: fixed;
  font-size: 2rem;
  background: black;
  top: 0;
  right: 0;
  left: 0;
  color: white;
  display: flex;
  justify-content: space-evenly;
  user-select: none;
  flex-wrap: wrap;
}

.opInfoItem {
  color: #9b9b9b;
  font-size: 2rem;
  padding: 0.2rem 1rem;
  white-space: nowrap;
  flex: 1;
  text-align: center;
}
.opPlayTime {
  text-transform: none;
}

.opClock {
}

@media (orientation: landscape) {
  .opInfoItem {
    font-size: 1rem;
  }
}
