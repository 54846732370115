.opWrapper #gameLimitsView {
  opacity: 0;
  display: block;
  visibility: hidden;
  transition: opacity 0.3s;
}

.opWrapper #gameLimitsContainer {
  width: clamp(30%, 65rem, 90vw);
  position: fixed;
  top: 50%;
  left: 50%;
  user-select: none;
  box-sizing: content-box;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  font-family: 'Ubuntu bold', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  background: rgb(59, 60, 90);
  background: linear-gradient(0deg, rgba(59, 60, 90, 1) 28%, rgba(48, 48, 63, 1) 72%);
  color: #ffffde;
  overflow: hidden;
}

.opWrapper #gameLimitsContainer .gameLimits__head,
.opWrapper #gameLimitsContainer .gameLimitsTable {
  padding: min(2rem, 14px) min(3rem, 20px);
}

.opWrapper .gameLimits__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3rem;
  background-color: rgba(36, 36, 44, 1);
}

.opWrapper #gameLimitsClose {
  font-size: clamp(20px, 3rem, 4vh);
  cursor: pointer;
  color: rgb(255, 185, 0);
  background: linear-gradient(0deg, rgba(255, 185, 0, 1) 0%, rgba(252, 89, 119, 1) 55%, rgba(231, 0, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.opWrapper #gameLimitsClose:hover {
  opacity: 0.8;
}

.opWrapper #gameLimitsClose:active {
  opacity: 0.6;
}

.opWrapper #gameLimitsTitle {
  font-size: clamp(2vh, 3rem, 5vw);
}

.opWrapper .gameLimitsTable .row {
  padding: min(1rem, 1vw) 0;
  font-size: clamp(2vh, 2rem, 4vw);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
}

.opWrapper .gameLimits__amount {
  padding: 0.5rem min(1rem, 10px);
  background: #24242e;
  border-radius: 20px;
  white-space: nowrap;
}



